import React from "react"
import { Layout, Header } from "../components"
import { GatsbySeo, LocalBusinessJsonLd } from "gatsby-plugin-next-seo"

const MarketingPlatform = () => {
  return (
    <Layout>
      <GatsbySeo
        title={process.env.SITE_NAME}
        description="To Be On The Web voor wordpress websites, web applicaties en hosting."
      />
      <LocalBusinessJsonLd
        type="LocalBusiness"
        id={process.env.SITE_URL}
        name={process.env.SITE_NAME}
        description="To Be On The Web voor wordpress websites, web applicaties en hosting."
        url={process.env.SITE_URL}
        telephone="06-29478385"
        address={{
          streetAddress: "Kievit 12 b-14",
          addressLocality: "Baarle-Nassau",
          postalCode: "5111HD",
          addressCountry: "NL",
        }}
        geo={{
          latitude: "51.42526",
          longitude: "4.9606503",
        }}
        images={["https://www.tobeontheweb.nl/programming.jpg"]}
      />
      <Header />

      <section className="max-w-screen-xl mx-auto px-4 py-12">
        <h2 className="font-heading mb-2 text-4xl leading-tight">
          E-mail marketing platform
        </h2>
        <div className="">
          <ul className="mt-2 list-disc">
            <li className="ml-4">Betrouwbare email verzending </li>
            <li className="ml-4">Scherp geprijsd</li>
            <li className="ml-4">Onbeperkt aantal abonnees</li>
            <li className="ml-4">Onbeperkt aantal email- lijsten / groepen</li>
            <li className="ml-4">Uitgebreide statistieken</li>
            <li className="ml-4">Integratie met Wordpress</li>
          </ul>
        </div>
        <div className="mt-6">
          Uw nieuwsbrieven zijn eenvoudig op te stellen met behulp van een
          Content Management Systeem.
        </div>
        <div className="mt-6">
          50.000 emails verzenden per maand voor slechts 7.50 EUR (excl. BTW)
          per maand. Niet genoeg? Uitbreiding voor elke 10.000 mails voor 1.50
          (excl. BTW) EUR per maand.
        </div>
      </section>
    </Layout>
  )
}

export default MarketingPlatform
